import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { breakpoints, media } from "~/styles/breakpoints"
import SEO from "~/components/seo"
import Header from "~/components/Header"
import Container from "~/components/Container"
import {
  Intro,
  IntroText,
  IntroImage,
  Columns,
  Column,
} from "~/components/Layout"
import {
  PrimaryTitle,
  SecondaryTitle,
  Lead,
  SmallParagraph,
} from "~/components/Typography"
import ReactMarkdown from "react-markdown"
import pageData from "../../content/pages/about.json"

export const query = graphql`
  query {
    jangaImage: file(relativePath: { eq: "janga.jpg" }) {
      childImageSharp {
        fixed(width: 468, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    obiImage: file(relativePath: { eq: "obi-2.jpg" }) {
      childImageSharp {
        fixed(width: 468, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    alexImage: file(relativePath: { eq: "alex.jpg" }) {
      childImageSharp {
        fixed(width: 468, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

export default ({ data }) => {
  const {
    metaTitle,
    metaDescription,
    metaImage,
    title,
    lead,
    text,
    profiles,
  } = pageData

  const images = [data.jangaImage, data.obiImage, data.alexImage]

  return (
    <>
      <SEO title={metaTitle} description={metaDescription} image={metaImage} />
      <Header />
      <Wrapper>
        <Intro gradient="#edf6e4">
          <Container>
            <IntroText>
              <Title style={{ maxWidth: "6em" }}>{title}</Title>
              <Lead>
                <ReactMarkdown
                  children={lead}
                  disallowedElements={["p"]}
                  unwrapDisallowed
                />
              </Lead>
              <Text>
                <ReactMarkdown children={text} />
              </Text>
            </IntroText>
            <IntroImage
              border
              src="/images/lemurs-on-grass.jpg"
              srcSet="/images/lemurs-on-grass.jpg 2x"
              loading="lazy"
            />
          </Container>
        </Intro>
        <Profiles>
          <Container>
            {profiles.map(({ name, smallText, date, text }, index) => (
              <Profile key={index}>
                <Column>
                  <Illustration
                    fixed={images[index].childImageSharp.fixed}
                    alt={name}
                  />
                </Column>
                <Column>
                  <SecondaryTitle>{name}</SecondaryTitle>
                  {smallText && <SmallParagraph>{smallText}</SmallParagraph>}
                  {date && <SmallParagraph>{date}</SmallParagraph>}
                  <Text>
                    <ReactMarkdown children={text} />
                  </Text>
                </Column>
              </Profile>
            ))}
          </Container>
        </Profiles>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div``

const Title = styled(PrimaryTitle)`
  margin-bottom: 50px;
  background: -webkit-linear-gradient(#01947d, #395489);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const Text = styled.div`
  p {
    margin-bottom: 1em;

    a {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      transition: 0.15s;

      &:hover {
        color: #829794;
      }
    }
  }
`

const Profiles = styled.div`
  padding: 150px 0 0;
`

const Profile = styled(Columns)`
  margin-bottom: 150px;

  ${media.tablet} {
    &:nth-child(odd) {
      flex-direction: row-reverse;
    }
  }
`

const Illustration = styled(Img)`
  max-width: 100%;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    height: auto !important;

    img {
      position: static !important;

      &[aria-hidden] {
        display: none;
      }
    }
  }

  ${media.tablet} {
    margin-top: 60px;
  }
`
